var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('div', {
    attrs: {
      "id": "contents"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "ui-components-wrap"
  }, [_c('h3', [_vm._v("{breakpoint} : sm"), _c('small', [_vm._v("(min-width:576px)")]), _vm._v(", md"), _c('small', [_vm._v("(min-width:768px)")]), _vm._v(", lg"), _c('small', [_vm._v("(min-width:1024px)")]), _vm._v(", xl"), _c('small', [_vm._v("(min-width:1200px)")])]), _c('h3', [_vm._v("{size} : 2px씩 늘어납니다")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Spacing")]), _c('h3', [_vm._v("{direction}")]), _c('p', {
    staticClass: "font-size-18"
  }, [_c('b', [_vm._v("t")]), _vm._v(" - margin-top and padding-top"), _c('br'), _c('b', [_vm._v("b")]), _vm._v(" - margin-bottom and padding-bottom"), _c('br'), _c('b', [_vm._v("l")]), _vm._v(" - margin-left and padding-left"), _c('br'), _c('b', [_vm._v("r")]), _vm._v(" - margin-right and padding-right"), _c('br'), _c('b', [_vm._v("s")]), _vm._v(" - margin-left/padding-left (in LTR mode) and margin-right/padding-right (in RTL mode)"), _c('br'), _c('b', [_vm._v("e")]), _vm._v(" - margin-right/padding-right (in LTR mode) and margin-left/padding-left (in RTL mode)"), _c('br'), _c('b', [_vm._v("x")]), _vm._v(" - both *-left and *-right"), _c('br'), _c('b', [_vm._v("y")]), _vm._v(" - both *-top and *-bottom"), _c('br'), _c('b', [_vm._v("a")]), _vm._v(" - the property in all directions ")]), _c('h3', [_vm._v("{size} : 0 ~ 300(px), n2 ~ n300(-2px ~ -300px)")]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v("padding일때, "), _c('u', [_c('b', [_vm._v("p")]), _vm._v("{direction}-{breakpoint}-{size}")])]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v("margin일때, "), _c('u', [_c('b', [_vm._v("m")]), _vm._v("{direction}-{breakpoint}-{size}")])]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v("예시) pa-md-16, mt-sm-10")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Sizing")]), _c('h3', [_vm._v("{abbrev}")]), _c('p', {
    staticClass: "font-size-18"
  }, [_c('b', [_vm._v("w")]), _vm._v(" - width"), _c('br'), _c('b', [_vm._v("mw")]), _vm._v(" - max-width"), _c('br'), _c('b', [_vm._v("min-w")]), _vm._v(" - min-width"), _c('br'), _c('b', [_vm._v("h")]), _vm._v(" - height"), _c('br'), _c('b', [_vm._v("mh")]), _vm._v(" - max-height"), _c('br'), _c('b', [_vm._v("min-h")]), _vm._v(" - min-height"), _c('br'), _c('b', [_vm._v("vw")]), _vm._v(" - width"), _c('br'), _c('b', [_vm._v("vh")]), _vm._v(" - height"), _c('br'), _c('b', [_vm._v("min-vw")]), _vm._v(" - min-width"), _c('br'), _c('b', [_vm._v("min-vh")]), _vm._v(" - min-height"), _c('br')]), _c('h3', [_vm._v("{size}")]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v(" px - "), _c('b', [_vm._v("10px ~ 500px")]), _vm._v("(10px씩 늘어납니다), auto"), _c('br'), _vm._v(" % - "), _c('b', [_vm._v("1 ~ 100")]), _vm._v("(1%씩 늘어납니다)"), _c('br'), _vm._v(" vw, vh - "), _c('b', [_vm._v("100")]), _vm._v("만 있습니다"), _c('br')]), _c('br'), _c('p', {
    staticClass: "font-size-18"
  }, [_c('u', [_vm._v("{abbrev}-{breakpoint}-{size}")])]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v("예시) w-10px, mh-md-100px, w-100, min-vw-100, vh-100")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Font")]), _c('h2', [_vm._v("Color")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("primary")]), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("secondary")]), _c('span', {
    staticClass: "accent--text"
  }, [_vm._v("accent")]), _c('span', {
    staticClass: "error--text"
  }, [_vm._v("error")]), _c('span', {
    staticClass: "info--text"
  }, [_vm._v("info")]), _c('span', {
    staticClass: "success--text"
  }, [_vm._v("success")]), _c('span', {
    staticClass: "warning--text"
  }, [_vm._v("warning")]), _c('br'), _c('br'), _c('h2', [_vm._v("Size")]), _c('h3', [_vm._v("{size} : 10 ~ 120")]), _c('p', {
    staticClass: "font-size-18"
  }, [_c('u', [_vm._v("font-size-{breakpoint}-{size}")])]), _c('p', {
    staticClass: "font-size-18"
  }, [_vm._v("예시) font-size-14, font-size-md-16")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Button")]), _c('h2', [_vm._v("Color")]), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("primary Button")]), _c('v-btn', {
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v("secondary Button")]), _c('v-btn', {
    attrs: {
      "color": "accent"
    }
  }, [_vm._v("accent Button")]), _c('v-btn', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("error Button")]), _c('v-btn', {
    attrs: {
      "color": "info"
    }
  }, [_vm._v("info Button")]), _c('v-btn', {
    attrs: {
      "color": "success"
    }
  }, [_vm._v("success Button")]), _c('v-btn', {
    attrs: {
      "color": "warning"
    }
  }, [_vm._v("warning Button")])], 1), _c('br'), _c('br'), _c('h2', [_vm._v("Style")]), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("Button")]), _c('v-btn', {
    attrs: {
      "tile": "",
      "color": "primary"
    }
  }, [_vm._v("Tile Button")]), _c('v-btn', {
    attrs: {
      "rounded": "",
      "color": "primary"
    }
  }, [_vm._v("Rounded Button")]), _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined Button")]), _c('v-btn', {
    attrs: {
      "rounded": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("Outlined Rounded Button")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_vm._v("Text Button")]), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("Block Button")])], 1), _c('br'), _c('h2', [_vm._v("Size")]), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("Extra small Button")]), _c('v-btn', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("Small Button")]), _c('v-btn', [_vm._v("Default Button")]), _c('v-btn', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("Large Button")]), _c('v-btn', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("Extra large Button")])], 1), _c('br'), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "x-large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('br'), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "x-small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "x-large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1), _c('br'), _c('h2', [_vm._v("Group")]), _c('div', {
    staticClass: "v-btn--group v-btn--group--x-small"
  }, [_c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")])], 1), _c('div', {
    staticClass: "v-btn--group v-btn--group--small"
  }, [_c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")])], 1), _c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")]), _c('v-btn', [_vm._v("Default Button")])], 1), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Form")]), _c('h2', [_vm._v("Input")]), _c('v-text-field', {
    staticClass: "d-inline-flex",
    attrs: {
      "hide-details": "",
      "placeholder": "InlineInput"
    }
  }), _c('br'), _c('br'), _c('v-text-field', {
    attrs: {
      "hide-details": "",
      "placeholder": "DefaultInput"
    }
  }), _c('br'), _c('v-text-field', {
    staticClass: "v-input--large",
    attrs: {
      "hide-details": "",
      "placeholder": "LargeInput"
    }
  }), _c('br'), _c('v-text-field', {
    attrs: {
      "filled": "",
      "hide-details": "",
      "placeholder": "FilledInput"
    }
  }), _c('br'), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "OutlinedInput"
    }
  }), _c('br'), _c('v-text-field', {
    attrs: {
      "solo": "",
      "hide-details": "",
      "placeholder": "SoloInput"
    }
  }), _c('br'), _c('br'), _c('h2', [_vm._v("Select")]), _c('v-select', {
    staticClass: "d-inline-flex",
    attrs: {
      "hide-details": "",
      "placeholder": "Inline"
    }
  }), _c('br'), _c('br'), _c('v-select', {
    attrs: {
      "hide-details": "",
      "placeholder": "Standard"
    }
  }), _c('br'), _c('v-select', {
    attrs: {
      "filled": "",
      "hide-details": "",
      "placeholder": "Filled"
    }
  }), _c('br'), _c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "Outlined"
    }
  }), _c('br'), _c('v-select', {
    attrs: {
      "solo": "",
      "hide-details": "",
      "placeholder": "Solo"
    }
  }), _c('br'), _c('br'), _c('h2', [_vm._v("Radio")]), _c('h3', [_vm._v("Color")]), _c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "primary",
      "color": "primary",
      "value": "primary"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "secondary",
      "color": "secondary",
      "value": "secondary"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "accent",
      "color": "accent",
      "value": "accent"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "error",
      "color": "error",
      "value": "error"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "info",
      "color": "info",
      "value": "info"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "success",
      "color": "success",
      "value": "success"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "warning",
      "color": "warning",
      "value": "warning"
    }
  })], 1), _c('br'), _c('h3', [_vm._v("Direction")]), _c('v-radio-group', {
    attrs: {
      "column": "",
      "hide-details": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "RadioColumn"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "RadioColumn"
    }
  })], 1), _c('v-radio-group', {
    attrs: {
      "row": "",
      "hide-details": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "RadioRow"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "RadioRow"
    }
  })], 1), _c('br'), _c('h3', [_vm._v("States")]), _c('v-radio-group', {
    attrs: {
      "hide-details": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "default"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "disabled",
      "disabled": ""
    }
  })], 1), _c('br'), _c('br'), _c('h2', [_vm._v("Checkbox")]), _c('h3', [_vm._v("Color")]), _c('div', {
    staticClass: "checkbox-group checkbox-group--row"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "primary",
      "color": "primary",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "secondary",
      "color": "secondary",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "accent",
      "color": "accent",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "error",
      "color": "error",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "info",
      "color": "info",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "success",
      "color": "success",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "warning",
      "color": "warning",
      "value": "",
      "input-value": "true",
      "hide-details": ""
    }
  })], 1), _c('br'), _c('h3', [_vm._v("Direction")]), _c('div', {
    staticClass: "checkbox-group checkbox-group--column"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "CheckboxColumn",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "CheckboxColumn",
      "hide-details": ""
    }
  })], 1), _c('div', {
    staticClass: "checkbox-group checkbox-group--row"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "CheckboxRow",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "CheckboxRow",
      "hide-details": ""
    }
  })], 1), _c('br'), _c('h3', [_vm._v("States")]), _c('v-checkbox', {
    attrs: {
      "label": "off",
      "value": "",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "on",
      "input-value": "true",
      "value": "",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "indeterminate",
      "value": "",
      "indeterminate": "",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "on disabled",
      "input-value": "true",
      "value": "",
      "disabled": "",
      "hide-details": ""
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": "off disabled",
      "value": "",
      "disabled": "",
      "hide-details": ""
    }
  }), _c('br'), _c('br'), _c('h2', [_vm._v("Textarea")]), _c('v-textarea', {
    staticClass: "d-inline-flex",
    attrs: {
      "hide-details": "",
      "placeholder": "Inline"
    }
  }), _c('br'), _c('br'), _c('v-textarea', {
    attrs: {
      "hide-details": "",
      "placeholder": "Default"
    }
  }), _c('br'), _c('v-textarea', {
    attrs: {
      "solo": "",
      "hide-details": "",
      "placeholder": "Solo"
    }
  }), _c('br'), _c('v-textarea', {
    attrs: {
      "filled": "",
      "hide-details": "",
      "placeholder": "Filled"
    }
  }), _c('br'), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "Outlined"
    }
  }), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Tab")]), _c('p', [_vm._v("tab에 tab--sticky 클래스를 넣으면 상단에 붙습니다.")]), _c('br'), _c('h2', [_vm._v("모바일에서 셀렉트박스로 변경되는 탭")]), _c('div', {
    staticClass: "tab-wrap tab-wrap--selectbox"
  }, [_c('button', {
    staticClass: "tab-wrap__btn"
  }, [_vm._v("메뉴명")]), _c('ul', {
    staticClass: "tab"
  }, [_c('li', {
    staticClass: "tab__li active"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('a', {
    staticClass: "tab__btn",
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("메뉴명")])])])])]), _c('br'), _c('h2', [_vm._v("Block")]), _c('ul', {
    staticClass: "tab tab--block"
  }, [_c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('h2', [_vm._v("Punch")]), _c('ul', {
    staticClass: "tab tab--punch"
  }, [_c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('h2', [_vm._v("Line")]), _c('ul', {
    staticClass: "tab tab--line"
  }, [_c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('h2', [_vm._v("Underline")]), _c('ul', {
    staticClass: "tab tab--underline"
  }, [_c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('h2', [_vm._v("Underline Inline")]), _c('ul', {
    staticClass: "tab tab--underline-inline"
  }, [_c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('h2', [_vm._v("Inline Tab")]), _c('ul', {
    staticClass: "tab tab--inline"
  }, [_c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li active"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])]), _c('li', {
    staticClass: "tab__li"
  }, [_c('button', {
    staticClass: "tab__btn"
  }, [_c('span', [_vm._v("메뉴명")])])])]), _c('br'), _c('v-divider', {
    staticClass: "my-20"
  }), _c('h1', [_vm._v("Common Text")]), _c('h2', [_vm._v("Title")]), _c('h3', [_vm._v("Size")]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("제목이들어갑니다")])]), _c('h3', [_vm._v("Align")]), _c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-wrap text-right"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-txt-wrap text-center"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('p', {
    staticClass: "tit-txt"
  }, [_vm._v("추가설명을입력해주세요")])]), _c('h3', [_vm._v("text")]), _c('div', {
    staticClass: "tit-txt-wrap"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('p', {
    staticClass: "tit-txt"
  }, [_vm._v("추가설명을입력해주세요")])]), _c('h3', [_vm._v("Style")]), _c('div', {
    staticClass: "tit-wrap tit-wrap--line"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("제목이들어갑니다")])]), _c('div', {
    staticClass: "tit-txt-wrap"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("제목이들어갑니다")])]), _c('p', {
    staticClass: "tit-txt"
  }, [_vm._v("추가설명을입력해주세요")])]), _c('div', {
    staticClass: "tit-wrap tit-wrap--unberline"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("제목이들어갑니다")])]), _c('br'), _c('h2', [_vm._v("Page Text (공통으로 사용되는 텍스트스타일)")]), _c('h3', [_vm._v("단락구분이 없을 경우")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('br'), _c('h3', [_vm._v("단락구분이 있을 경우")]), _c('div', {
    staticClass: "page-text"
  }, [_c('p', [_vm._v("동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('p', [_vm._v("남산 위에 저 소나무 철갑을 두른 듯 바람 서리 불변함은 우리 기상일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('p', [_vm._v("가을 하늘 공활한데 높고 구름 없이 밝은 달은 우리 가슴 일편단심일세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")]), _c('p', [_vm._v("이 기상과 이 맘으로 충성을 다하여 괴로우나 즐거우나 나라 사랑하세 무궁화 삼천리 화려 강산 대한 사람 대한으로 길이 보전하세")])])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }