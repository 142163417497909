var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('sub-visual', {
    attrs: {
      "tit": "개인정보처리방침",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('p', {
    staticClass: "page-text--lg line-height-15"
  }, [_vm._v(" 민아트프레임의 개인정보 수집·이용에 관한 안내입니다. "), _c('br'), _c('br'), _c('br'), _vm._v(" 개인정보활용동의"), _c('br'), _vm._v(" 귀사가 본인으로부터 취득한 개인신용정보를 귀사가 본인의 개인신용정보를 수집 · 이용하거나 제3자에게 제공하고자 하는 경우에는 개인신용정보법 제15조제1항 제1호, 제17조1항 제1호 및 신용정보의 이용 및 보호에 관한법률 제32조제1항, 제33조 및 제34조에 따라 본인의 사전 동의를 얻어야 합니다."), _c('br'), _vm._v(" 이에 본인은 귀사가 본인의 개인신용정보를 아래와 같이 이용하는데 동의합니다."), _c('br'), _c('br'), _vm._v(" -서비스명 : 제품문의"), _c('br'), _vm._v(" -유튜브-수집업체 : 민아트프레임"), _c('br'), _vm._v(" -수집정보 : 이름, 이메일, 회사명, 전화번호, 문의내용 등"), _c('br'), _vm._v(" -이용목적 :해당제품에 대한 안내/상담"), _c('br'), _vm._v(" -보유 및 이용기간 :상담신청 후 철회시까지"), _c('br'), _vm._v(" -정보철회방법 :업체별 전화요청 ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }