<template>
    <div>
        <sub-visual tit="개인정보처리방침" bg="/images/sub/visual/sv--about.jpg"></sub-visual>

        <section class="section">
            <v-container>
                <p class="page-text--lg line-height-15">
                    민아트프레임의 개인정보 수집·이용에 관한 안내입니다. <br><br><br>

                    개인정보활용동의<br>
                    귀사가 본인으로부터 취득한 개인신용정보를 귀사가 본인의 개인신용정보를 수집 · 이용하거나 제3자에게 제공하고자 하는 경우에는 개인신용정보법 제15조제1항 제1호, 제17조1항 제1호 및 신용정보의 이용 및 보호에 관한법률 제32조제1항, 제33조 및 제34조에 따라 본인의 사전 동의를 얻어야 합니다.<br>
                    이에 본인은 귀사가 본인의 개인신용정보를 아래와 같이 이용하는데 동의합니다.<br><br>

                    -서비스명 : 제품문의<br>
                    -유튜브-수집업체 : 민아트프레임<br>
                    -수집정보 : 이름, 이메일, 회사명, 전화번호, 문의내용 등<br>
                    -이용목적 :해당제품에 대한 안내/상담<br>
                    -보유 및 이용기간 :상담신청 후 철회시까지<br>
                    -정보철회방법 :업체별 전화요청
                </p>
            </v-container>
        </section>
    </div>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";

export default {
    components: {
        SubVisual,
    },
};
</script>

<style scoped>

</style>
